
import './main.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useEffect, useRef,useState } from 'react';

import BannerImage from './assets/images/lion_banner.jpg';
import LogoImage from './assets/images/lion_logo.png';
import { 
    Chart as ChartJS, 
    CategoryScale, 
    ArcElement, 
    Tooltip, 
    Legend,
    LinearScale,
    LineElement,
    Filler,
    BarElement,
    PointElement,
    plugins,
    elements,
    scales
} from 'chart.js';
import { Doughnut, Line, Pie } from 'react-chartjs-2';
import { Bar } from 'react-chartjs-2';

library.add(fas, fab);

const chartsColor =  "#99035a"
const chartsColor2 = "#54d3ca"
const chartsColor3 = "#030059"

const chartsColor4 = "#f40181"
const chartsColor5 = "#dbd0ba"
const chartsColor6 = "#a1936c"



const shadowPlugin = {
    id: 'shadowPlugin',
    beforeDatasetDraw: (chart) => {
      const ctx = chart.ctx;
      ctx.save();
      ctx.shadowColor = 'rgb(58 58 58 / 50%)';
      ctx.shadowBlur = 10;
      ctx.shadowOffsetX = 5;
      ctx.shadowOffsetY = 5;
      ctx.restore();
    }
  };

  const centerTextPlugin = {
    id: 'centerTextPlugin',
    beforeDraw: (chart, args, options) => {
      if (chart.config.type === 'doughnut' && options.display !== false) {
        const { width, height, ctx } = chart;
        ctx.restore();
        const fontSize = (height / 200).toFixed(2);
        ctx.font = `${fontSize}em sans-serif`;
        ctx.textBaseline = 'middle';
        
    
        const text = options.text || '';
        const textX = Math.round((width - ctx.measureText(text).width) / 2) - options.legendOffset;
        const textY = height / 2;
    
        ctx.fillText(text, textX, textY);
        ctx.save();
      }
    }
  };

ChartJS.register(
    ArcElement, 
    Tooltip, 
    Legend, 
    shadowPlugin, 
    CategoryScale,
    LinearScale,
    LineElement,
    BarElement,
    PointElement,
    Filler,
    ChartDataLabels,
    centerTextPlugin);

const lineOptions = {
    plugins:{
        legend:{
            display:false,
        },
        datalabels: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Background color for data labels
            borderRadius: 20,
            color: 'white',
            display: false, //hiden temporarily
            font: {
              weight: 'bold',
              size: 11

            },
            padding: 3,
            formatter: (value) => {
              return value;
            }
          }
    },
    elements:{
        line:{
            tension:0,
            borderWidth:2,
            borderColor: "rgba(47,97,68,1)",
            fill:"start",
            backgroundColor: /*"rgba(47,97,68,0.3)"*/ chartsColor,
        },
        point:{
            radius: 0,
            hitRadius:0,
            backgroundColor: "black",
        },
    },
    scales:{
        x:{
            //display:false,
        },
        y:{
            //display:false,
        }
    }
}

const lineData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June'],
    datasets: [
      {
        label: 'Temp dataset',
        data: [65, 59, 80, 81, 56, 55],
        fill: true,
        borderColor: chartsColor,
        tension: 0.1,
      }
    //   {
    //     label: 'Temp dataset 2',
    //     data: [65, 59, 80, 81, 56, 55, 40].reverse(),
    //     fill: false,
    //     borderColor: '#0e2bcd',
    //     tension: 0.1,
    //   },
    ],
};


const barOptions = {
    plugins: {
      legend: {
        display: false,
        labels: {
          boxWidth: 7,
          usePointStyle: true,
          pointStyle: 'circle',
        },
        title: {
          text: 'Placeholder title',
          display: false, // disabled for now
          color: "#000",
          font: {
            size: 18
          }
        }
      },
      datalabels: {
        display:false
      }
    },
    scales: {
      x: {
       // display: false,
        // stacked: true,
      },
      y: {
       // display: false,
        // stacked: true,
        // max: 1
      }
    },
    elements: {
      bar: {
        borderWidth: 1,
        barThickness: 20, 
        maxBarThickness: 30, 
      }
    },
    height:'100px',
    //barPercentage: 0.4,
    //categoryPercentage: 0.4,
  };


const barData = {
   labels: ['Total', 'Brands'],
   datasets: [
     {
       labels: 'Total Downloads',
       data: [65, 40],
       backgroundColor: [
        chartsColor,
        chartsColor2,
         '#54d3ca',
         '#a1936c',
         '#dbd0ba',
         '#99035a',
       ],
       borderRadius: 5,
       barThickness: 40,
     },
    //  {
    //     label: 'Downloaded by Brand Name',
    //     data: [25].reverse(),
    //     backgroundColor: [
    //       '#030059',
    //       '#0e2bcd',
    //       '#54d3ca',
    //       '#a1936c',
    //       '#dbd0ba',
    //       '#99035a',
    //     ],
    //     borderRadius: 30,
    //     barThickness: 10,
    //   },
   ],
}

const feedbackImpactOnProductionData = {
    labels: ['High Impact', 'Medium Impact', 'Low Impact'],
    datasets: [
      {
        labels: 'Feedback Impact on Production',
        data: [60, 30, 10],
        backgroundColor: [
         chartsColor,
         chartsColor2,
         chartsColor3,
          '#a1936c',
          '#dbd0ba',
          '#99035a',
        ],
        borderRadius: 5,
        barThickness: 40,
      }
    ],
 }

 const wastageKPIData = {
    labels: [
        'TV', 
        'Online Video', 
        'Paid Social', 
        'Programmatic', 
        'Print', 
        'Online Display',
        'Other Digital',
        'OOH',
        'Influencer',
        'CEO',
        'Partnership',
        'Digital Video'],
    datasets: [
      {
        labels: 'Feedback Impact on Production',
        data: [145, 30, 32, 20,  10, 5, 5, 5, 2, 2, 2, 2],
        backgroundColor: [
         chartsColor,
         chartsColor2,
        ],
        borderRadius: 5,
        barThickness: 40,
      },
      {
        labels: 'Feedback Impact on Production',
        data: [30, 8, 7, 0,  1, 2, 1, 1, 2, 0, 1, 2],
        backgroundColor: [
          chartsColor2,
          chartsColor,
        ],
        borderRadius: 5,
        barThickness: 40,
      }
    ],
 }

const doughnutData = {
    backgroundColor:[
        "rgb(2, 88, 255)",
        "rgb(249, 151, 0)",
        "rgb(255,199,0)",
        "rgb(32, 214, 152)"
    ],
    labels: ['Reused Content', 'Original Content'],
    datasets: [
        {
            label: '',
            data: [30, 40],
            backgroundColor: [
                chartsColor,
                chartsColor2,
                chartsColor3,
                "rgb(255,199,0)",
                "rgb(32, 214, 152)"
            ],
            hoverOffset: 4
        }
    ]
}

const doughnutData2 = {
    backgroundColor:[
        "rgb(2, 88, 255)",
        "rgb(249, 151, 0)",
        "rgb(255,199,0)",
        "rgb(32, 214, 152)"
    ],
    labels: ['Ad-Hoc Content', 'Content from Media Plans'],
    datasets: [
        {
            label: '',
            data: [33.3, 66.7],
            backgroundColor: [
                chartsColor,
                chartsColor2,
                chartsColor3,
                "rgb(255,199,0)",
                "rgb(32, 214, 152)"
            ],
            hoverOffset: 4
        }
    ]
}

const doughnutData3 = {
  backgroundColor:[
    chartsColor,
    chartsColor2,
    chartsColor3,
    chartsColor4,
    chartsColor5,
    chartsColor6,
  ],
  labels: ['New', 'Working on it', 'On Hold', 'Proposal Review', 'Send for Triage', 'Approved'],
  datasets: [
      {
          label: '',
          data: [15, 35, 10, 20, 15, 10],
          backgroundColor: [
              chartsColor,
              chartsColor2,
              chartsColor3,
              chartsColor4,
              chartsColor5,
              chartsColor6,
          ],
          hoverOffset: 4
      }
  ]
}

const doughnutData4 = {
  backgroundColor:[
    chartsColor,
    chartsColor2,
    chartsColor3,
    chartsColor4,
    chartsColor5,
    chartsColor6,
  ],
  labels: ['Delivered', 'Undelivered'],
  datasets: [
      {
          label: '',
          data: [87, 13],
          backgroundColor: [
              chartsColor,
              chartsColor2,
              chartsColor3,
              chartsColor4,
              chartsColor5,
              chartsColor6,
          ],
          hoverOffset: 4
      }
  ]
}

var mediaPlanUpdateDoughnutData = {
    backgroundColor:[
        "rgb(2, 88, 255)",
        "rgb(249, 151, 0)",
        "rgb(255,199,0)",
        "rgb(32, 214, 152)"
    ],
    labels: ['Content Revisions', 'Increased Content', 'Decreased Content'],
    datasets: [
        {
            label: '',
            data: [30, 30, 40],
            backgroundColor: [
                chartsColor,
                chartsColor2,
                chartsColor3,
                "rgb(255,199,0)",
                "rgb(32, 214, 152)"
            ],
            hoverOffset: 4
        }
    ]
}


const doughnutOptions = {
     plugins:{
      legend: {
         display: true,
         position:'right',
         labels: {
            boxWidth: 15,
            padding: 10,
          },
     },
      datalabels: {
        formatter: (value, context) => {
          const total = context.chart.data.datasets[0].data.reduce((acc, val) => acc + val, 0);
          const percentage = ((value / total) * 100).toFixed(2) + '%';
          return percentage;
        },
        color: '#fff',
        font: {
            size: 10
         // weight: 'bold'
        }
      },
      tooltip: {
        callbacks: {
          label: function(tooltipItem) {
            const dataset = tooltipItem.dataset;
            const total = dataset.data.reduce((acc, val) => acc + val, 0);
            const value = dataset.data[tooltipItem.dataIndex];
            const percentage = ((value / total) * 100).toFixed(2) + '%';
            return ` ${percentage}`;
          }
        }
      }
      
  },
  layout: {
    padding: {
      left: 50, 
      right: 50,
    },
  },
  elements: {
    arc: {
      weight: 0.5,
      borderWidth: 2,
    }
  },
  maintainAspectRatio: false,
  cutout: '60%'
}

const doughnutOptionsNoPercentage = {
   ...doughnutOptions,
   plugins:{
    ...doughnutOptions.plugins,
    datalabels:{
      ...doughnutOptions.plugins.datalabels,
      formatter: (value, context) => {
        return value;
      }
    }
   }

}

const pieOptions = {
  plugins:{
    legend: {
       display: true,
       position:'right',
       labels: {
          boxWidth: 15,
          padding: 10,
        },
   },
    datalabels: {
      formatter: (value, context) => {
        const total = context.chart.data.datasets[0].data.reduce((acc, val) => acc + val, 0);
        const percentage = ((value / total) * 100).toFixed(2) + '%';
        return percentage;
      },
      color: '#fff',
      font: {
          size: 10
       // weight: 'bold'
      }
    },
    tooltip: {
      callbacks: {
        label: function(tooltipItem) {
          const dataset = tooltipItem.dataset;
          const total = dataset.data.reduce((acc, val) => acc + val, 0);
          const value = dataset.data[tooltipItem.dataIndex];
          const percentage = ((value / total) * 100).toFixed(2) + '%';
          return ` ${percentage}`;
        }
      }
    }
    
},
layout: {
  padding: {
    left: 50, 
    right: 50,
  },
},
elements: {
  arc: {
    weight: 0.5,
    borderWidth: 2,
  }
},
maintainAspectRatio: false,
cutout: '0'
};

export const PieData = {
  labels: ['Processed', 'To be reviewed'],
  datasets: [
    {
      label: '',
      data: [67, 33],
      backgroundColor: [
        chartsColor,
        chartsColor2,
      ],
      borderColor: [
        chartsColor,
        chartsColor2,
      ],
      borderWidth: 1,
    },
  ],
};


function getRandomInt(max) {
    return Math.floor(Math.random() * max);
}

export const data = {
    labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [
      {
        label: '# of Votes',
        data: [3, 7, 8, 16, 20, 11],
        backgroundColor: [
          '#030059',
          '#0e2bcd',
          '#54d3ca',
          '#a1936c',
          '#dbd0ba',
          '#99035a',
        ],
        borderColor: [
            '#030059',
            '#0e2bcd',
            '#54d3ca',
            '#a1936c',
            '#dbd0ba',
            '#99035a',
        ],
        borderWidth: 1,
      },
    ],
  };


function getRandomPieData(sections){
    var dataCopy = {...data};
    //var sections = getRandomInt(6);
    var newData = [];
    for(var i=0; i<sections; i++){
        newData.push(getRandomInt(20));
    }
    return {
        labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
        datasets: [
            {
                label: '# of Votes',
                data: newData,
                backgroundColor: [
                    '#030059',
                    '#0e2bcd',
                    '#54d3ca',
                    '#a1936c',
                    '#dbd0ba',
                    '#99035a'
                ],
                borderColor: [
                    '#030059',
                    '#0e2bcd',
                    '#54d3ca',
                    '#a1936c',
                    '#dbd0ba',
                    '#99035a',
                ],
            }
        ]
    };
}

const centerdFlex = {
    height: '250px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}

function GetDashBoard(){
  return(
    <div className="main-container">
       <div className="side-menu">
         {/* <div className='logo'> */}
         <h4><FontAwesomeIcon icon={['fas', 'home']} /></h4>
         <h4><FontAwesomeIcon icon={['fas', 'user']} /></h4>
         <h4><FontAwesomeIcon icon={['fas', 'chart-line']} /></h4>
         <h4><FontAwesomeIcon icon={['fas', 'cog']} /></h4>
         <h4><FontAwesomeIcon icon={['fas', 'bell']} /></h4>
           {/* </div> */}
       </div>
       <div className="content">  
           <Section>
               <SectionItem>
                   <h1 style={{color:'#4c4c4c', display:'inline-block'}}><FontAwesomeIcon style={{paddingRight:'10px'}} icon={['fas', 'chart-line']} />Content Insights</h1>
               </SectionItem>
           </Section>
           <Section> 
               <SectionItem style={{flex:2}}>
                <InnerSection>
                    <InnerSectionItem>
                       <SectionHeading title = "Content Reuse"/>
                       <div className='pie-container' style={centerdFlex}>
                         <Doughnut data={doughnutData} options={{
                             ...doughnutOptions,
                             plugins:{
                                 ...doughnutOptions.plugins,
                                 centerTextPlugin:{
                                     text: '42.86%',
                                     legendOffset:60,
                                 }
                             }
                         }} key="some"/>
                       </div>
                     </InnerSectionItem>
                     <InnerSectionItem>
                       <SectionHeading title = "Content Downloads"/>
                       <Bar data={barData} options={barOptions} key="some1"/>
                     </InnerSectionItem>
                 </InnerSection>
               </SectionItem>
               <SectionItem  style={{flex:1}}>
                 <SectionHeading title = "Content Based on Media Plans"/>
                  <div className='pie-container' style={centerdFlex}>
                     <Doughnut 
                       data={doughnutData2} 
                       options={{
                         ...doughnutOptions,
                         plugins:{
                             ...doughnutOptions.plugins,
                             centerTextPlugin:{
                                 text: '33.3%',
                                 legendOffset:85,
                                
                             }
                         }
                     }} 
                     key="some"/>
                  </div>
               </SectionItem>
           </Section>
           <Section>
              <SectionItem style={{flex:1}}>
                <SectionHeading title = "Translated Content"/>
                <Line data={lineData} options={lineOptions} key="some2"/>
              </SectionItem>
              <SectionItem style={{flex:2}}>
                <InnerSection>
                  <InnerSectionItem>
                    <SectionHeading title = "Media Plan Updates From Feedback"/>
                    <div className='pie-container' style={centerdFlex}>
                      <Doughnut 
                        data={mediaPlanUpdateDoughnutData}  
                        options={{
                         ...doughnutOptions,
                         plugins:{
                             ...doughnutOptions.plugins,
                             centerTextPlugin:{
                                 text: '30%',
                                 legendOffset:65,
                             }
                         }
                        }} 
                        key="some"
                     />
                    </div>
                  </InnerSectionItem>
                  <InnerSectionItem>
                    <SectionHeading title = "Feedback Impact on Production"/>
                    <Bar data={feedbackImpactOnProductionData} options={barOptions} key="some1"/>
                 </InnerSectionItem>
                </InnerSection>
              </SectionItem>
           </Section>
           <Section>
              <SectionItem style={{height:'300px'}}>
                <SectionHeading title = "Wastage KPI"/>
                <div className='bar-wrapper' style={{height:250}}>
                  <Bar data={wastageKPIData} options={{...barOptions, maintainAspectRatio:false}} key="some1"/>
                </div>
              </SectionItem>
           </Section>
           <Section>
              <SectionItem>
              <SectionHeading title = "Brief Status"/>
                  <div className='pie-container' style={centerdFlex}>
                     <Doughnut 
                       data={doughnutData3} 
                       options={{
                         ...doughnutOptionsNoPercentage,
                         plugins:{
                             ...doughnutOptionsNoPercentage.plugins,
                             centerTextPlugin:{
                                 text: '10',
                                 legendOffset:66,
                                
                             }
                         }
                     }} 
                     key="some"/>
                  </div>
              </SectionItem>
              <SectionItem>
                <SectionHeading title = "Initial Submission Success"/>
                <div className='pie-container' style={centerdFlex}>
                  <Pie data={PieData} options={pieOptions} key="some1"/>
                 </div>
              </SectionItem>
              <SectionItem >
                <SectionHeading title = "On-Time Delivery"/>
                  <div className='pie-container' style={centerdFlex}>
                     <Doughnut 
                       data={doughnutData4} 
                       options={{
                         ...doughnutOptions,
                         plugins:{
                             ...doughnutOptions.plugins,
                             centerTextPlugin:{
                                 text: '87%',
                                 legendOffset:48,
                                
                             }
                         }
                     }} 
                     key="some"/>
                  </div>
              </SectionItem>
              </Section>
       </div>
    </div> 
 )
}

function CalculateManSalary(salary){

}


function LoginPage({onLogin}){

  const demoUsernames = ['mars', 'pfizer']
  const demoPassword = "123456";
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  

  const onUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const onPasswordChange = (event) => {
    setPassword(event.target.value);
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    if(!demoUsernames.includes(username)){
      setErrorMessage('Invalid credentials');
      return;
    }else if(password !== demoPassword){
      setErrorMessage('Invalid credentials');
      return;
    }

    // if(username !== demoUsername || password !== demoPassword){
    //   alert('Invalid credentials');
    //   return;
    // }

    onLogin(); 
  };
  return(
    <div className='login-dropback'>
      <div className='login-container'>
        <div className='login-form'>
          <div className='banner-wrapper'>
            <div className='banner-container'></div>
            <img className='logo-image' src={LogoImage} />
          </div>
       
        
          <div className='form-container' style={{flex:1}}>
            <h1>Login</h1>
            <form id="login-form" onSubmit={handleSubmit}>
              <input type='text'id="username-input" onChange={onUsernameChange} placeholder='Username'/>
              <input type='password' onChange={onPasswordChange} id="password-input"  placeholder='Password'/>
              <button type='submit'>Login</button>
            </form> 
            {errorMessage && <div className="error-message">{errorMessage}</div>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default function Main(){
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  
  useEffect(() => {
    //check if user is logged in
    //if not logged in, show login page
    //if logged in, show dashboard
  }, [isLoggedIn])

  if(isLoggedIn){
    return <GetDashBoard/>
  }else{
    return <LoginPage onLogin={handleLogin}/>  
  }
}



function InnerSection(props){
  return(
    <div className='inner-section' style={{display:'flex', flexDirection:'row'}}>
      {props.children}
    </div>
  )
}

function InnerSectionItem(props){
  return(
    <div className='inner-section-item' style={{flex:1}}>
      {props.children}
    </div>
  )
}
function SectionHeading (props){
  return(
    <div className='section-heading'>
      <h2 style={{marginTop:0, borderLeft: '3px solid', paddingLeft: '10px'}}>{props.title}</h2>
    </div>
  )
}

function Section(props){
  return(
    <div className='section'>
       {props.children}
    </div>
  )
}

function SectionItem(props){
   return(
     <div className={`section-item ${props.className}`} style={props.style}>
       {props.children}
     </div>
    )
}